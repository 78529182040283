import React from 'react';
import styled from "styled-components";

export default function ButtonSecondary({ text, onClick }) {
  return (
    <ButtonWrapper onClick={onClick}>
      {text}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
  padding: 1rem 4rem;
  border-radius: 50rem;
  border: none;
  background: transparent;
  color: #4DD0E1;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;


