import React from "react";
import { Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";

export const Routes = (props) => {
  return (
    <Switch location={props.location}>
      <Route exact path={["/"]} component={HomePage} />
    </Switch>
  );
};
