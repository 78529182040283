import React from "react";
import styled from "styled-components";
// Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LandingSection from "../components/Home/LandingSection";
import WhatIsSection from "../components/Home/WhatIsSection";
import ServicesSection from "../components/Home/ServicesSection";
import WhyElDoradoSection from "../components/Home/WhyElDoradoSection";
import HowItWorksSection from "../components/Home/HowItWorksSection";
import JoinUsSection from "../components/Home/JoinUsSection";

export default function HomePage() {
  return (
    <HomeWrapper>
      <div className="landing">
        <Navbar />
        <LandingSection />
      </div>
      <WhatIsSection />
      <ServicesSection />
      <WhyElDoradoSection />
      <HowItWorksSection />
      <JoinUsSection />

      <Footer />
    </HomeWrapper>
  );
}

const HomeWrapper = styled.section`
  .landing {
    padding: 0 8%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;
