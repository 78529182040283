import React from "react";
import styled from "styled-components";
import ButtonPrimary from "./ButtonPrimary";
import ButtonSecondary from "./ButtonSecondary";

export default function Navbar() {
  return (
    <NavbarWrapper>
      <img src={require("../assets/images/logo.png")} alt="el dorado" />

      <div className="buttons">
        <ButtonPrimary text="Regístrate" />
        <ButtonSecondary text="Iniciar Sesión" />
      </div>
    </NavbarWrapper>
  );
}

const NavbarWrapper = styled.nav`
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    .buttons {
      display: none;
    }
  }

  img {
    height: 5rem;
  }
`;
