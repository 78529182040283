import React from "react";
import styled from "styled-components";

export default function ServicesSection() {
  return (
    <ServicesSectionWrapper>
      <img
        src={require("../../assets/images/home/services-tree.png")}
        alt="services tree"
      />
      <div className="services">
        <div className="title">Servicios</div>
        <div className="services__service">
          <div className="subtitle">
            Cuenta de ahorros en dólares y bitcoins
          </div>
          <div className="text">
            Crea una billetera en dólares y bitcoins totalmente gratuita y con
            la mejor seguridad en la industria.
          </div>
        </div>
        <div className="services__service">
          <div className="subtitle">Mercado P2P</div>
          <div className="text">
            Organiza tus propias ofertas y conecta con una comunidad de usuarios
            con los métodos de pago que más te convengan
          </div>
        </div>
        <div className="services__service">
          <div className="subtitle">Envío de Remesas</div>
          <div className="text">
            Envía dinero protegido de la devaluación del bolívar desde cualquier
            parte del mundo a Venezuela.
          </div>
        </div>
        <div className="services__service">
          <div className="subtitle">Pago de múltiples servicios</div>
          <div className="text">
            Realiza pagos a tus amigos y comercios favoritos en dólares y
            bitcoins en cuestión de minutos.
          </div>
        </div>
      </div>
    </ServicesSectionWrapper>
  );
}

const ServicesSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 8%;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 500px) {
    padding: 2rem 8%;
  }

  .services {
    padding-left: 3rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) {
      padding-left: 0;
    }

    .title {
      margin-bottom: 1.5rem;
    }

    &__service {
      .subtitle {
        color: #ffb300;
        margin-bottom: 0.3rem;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  img {
    width: 45rem;

    @media (max-width: 900px) {
      margin-top: 4rem;
      width: 100%;
    }
  }
`;
