import React from "react";
import styled from "styled-components";

export default function WhyElDoradoSection() {
  return (
    <WhyElDoradoSectionWrapper>
      <h1 className="title">¿Por qué elegir El Dorado?</h1>
      <div className="reasons">
        <div className="reasons__reason">
          <img src={require("../../assets/images/home/usdc.png")} alt="usdc" />
          <h2 className="subtitle">USDC</h2>
          <p className="text">
            Es un protocolo de dólares estadounidenses cripto, diseñado por
            Coinbase y Circle, con respaldo en instituciones financieras en
            Estados Unidos.
            <span className="btn-link">(Ir a proyecto criptodolar)</span>
          </p>
        </div>
        <div className="reasons__reason">
          <img
            src={require("../../assets/images/home/compliance.png")}
            alt="compliance"
          />
          <h2 className="subtitle">Custodio</h2>
          <p className="text">
            En El Dorado trabajamos con Bitgo, uno de los mejores aliados
            internacionales para brindarte los mayores estándares de seguridad
            en la industria cripto.
            <span className="btn-link">(Ir a Q&A)</span>
          </p>
        </div>
        <div className="reasons__reason">
          <img
            src={require("../../assets/images/home/regulation.png")}
            alt="regulation"
          />
          <h2 className="subtitle">Regulación</h2>
          <p className="text">
            El Dorado cuenta con licencias y protocoles internacionales como
            institución financiera, para garantizar la seguridad de todas las
            operaciones en nuestra plataforma.
            <span className="btn-link">(Ir a Q&A)</span>
          </p>
        </div>
      </div>
    </WhyElDoradoSectionWrapper>
  );
}

const WhyElDoradoSectionWrapper = styled.div`
  padding: 5rem 8%;
  margin: 3rem 0;
  background: #f5f5f5;

  .reasons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4rem;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }

    &__reason {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 30%;

      @media (max-width: 600px) {
        width: 90%;
        margin-bottom: 4rem;
      }

      img {
        height: 14rem;
        margin-bottom: 1.5rem;
      }

      .subtitle {
        color: #ffb300;
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
      }
    }
  }
`;
