import React from 'react';
import { Routes } from './Routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
