import React from "react";
import styled from "styled-components";
import ButtonPrimary from "../ButtonPrimary";

export default function LandingSection() {
  return (
    <LandingSectionWrapper>
      <div className="landing-section">
        <div className="landing-section__info">
          <h1 className="title">
            Tu cuenta de ahorros en dólares en Venezuela
          </h1>
          <h2 className="subtitle">
            Comienza a intercambiar tus bolívares por dólares y otras
            criptomonedas en nuestro mercado digital-online-virtual.
          </h2>
          <div className="text">
            Contamos con la mejor seguridad en la industria.{" "}
            <span className="btn-link">(Ir a Q&A)</span>
          </div>
          <div className="text">
            Cumplimos con licencias y protocolos internacionales para tu
            seguridad. <span className="btn-link">(Ir a Q&A)</span>
          </div>
          <div className="landing-section__info__actions">
            <ButtonPrimary text="Regístrate" />
            <img
              src={require("../../assets/images/home/android.png")}
              alt="android"
            />
            <img
              src={require("../../assets/images/home/apple.png")}
              alt="apple"
            />
          </div>
        </div>
        <div className="landing-section__image">
          <img
            src={require("../../assets/images/home/mobile-preview.png")}
            alt="mobile preview"
          />
        </div>
      </div>
    </LandingSectionWrapper>
  );
}

const LandingSectionWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 0;
    align-items: flex-start;
  }

  .landing-section {
    display: flex;
    align-items: flex-start;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      padding-right: 7rem;

      @media (max-width: 900px) {
        margin-bottom: 4rem;
        padding-right: 0;
      }

      .title {
        margin: 2rem 0;
      }

      .subtitle {
        margin-bottom: 1.5rem;
      }

      .text {
        margin-bottom: 1rem;
      }

      &__actions {
        display: flex;
        align-items: center;
        margin-top: 3rem;

        img {
          height: 3.5rem;
          margin-left: 1.2rem;
          transition: 0.3s;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    &__image {
      @media (max-width: 900px) {
        width: 90%;
      }

      img {
        height: 70vh;
        min-height: 35rem;

        @media (max-width: 900px) {
          min-height: unset;
          height: unset;
          width: 100%;
        }
      }
    }
  }
`;
