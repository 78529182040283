import React from "react";
import styled from "styled-components";

export default function HowItWorksSection() {
  return (
    <HowItWorksSectionWrapper>
      <h1 className="title">¿Cómo funciona?</h1>
      <div className="steps">
        <div className="steps__step">
          <img
            src={require("../../assets/images/home/app-1.png")}
            alt="create account"
          />
          <h2 className="subtitle">Crea una cuenta</h2>
        </div>
        <div className="steps__step">
          <img
            src={require("../../assets/images/home/app-2.png")}
            alt="obtain wallets"
          />
          <h2 className="subtitle">
            Obtén tus billeteras en dólares y bitcoins
          </h2>
        </div>
        <div className="steps__step">
          <img
            src={require("../../assets/images/home/app-3.png")}
            alt="go to marketplace"
          />
          <h2 className="subtitle">Ingresa al mercado de compra y venta P2P</h2>
        </div>
        <div className="steps__step">
          <img
            src={require("../../assets/images/home/app-4.png")}
            alt="choose offer"
          />
          <h2 className="subtitle">Elige la oferta que más te convenga</h2>
        </div>
      </div>
    </HowItWorksSectionWrapper>
  );
}

const HowItWorksSectionWrapper = styled.div`
  padding: 5rem 8%;
  margin: 3rem 0;

  @media (max-width: 900px) {
    padding: 1rem 8%;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }

    &__step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 22%;

      @media (max-width: 700px) {
        width: 30rem;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      img {
        width: 90%;
      }

      h2.subtitle {
        text-transform: uppercase;
        color: #ffb300;
        margin-top: 1.5rem;
        text-align: center;

        @media (max-width: 700px) {
          font-size: 2.2rem;
        }
      }
    }
  }
`;
