import React from "react";
import styled from "styled-components";

export default function Footer() {
  return (
    <FooterWrapper>
      <img src={require("../assets/images/logo.png")} alt="el dorado" />

      <div className="links">
        <div className="links__link">
          <h2 className="subtitle">Compañía</h2>
          <a href="/" className="text">
            Sobre Nosotros
          </a>
          <a href="/" className="text">
            Contáctanos
          </a>
          <a href="/" className="text">
            Q&A
          </a>
          <a href="/" className="text">
            Servicios
          </a>
        </div>
        <div className="links__link">
          <h2 className="subtitle">Comunidad</h2>
          <a href="/" className="text">
            Facebook
          </a>
          <a href="/" className="text">
            Instagram
          </a>
          <a href="/" className="text">
            Telegram
          </a>
          <a href="/" className="text">
            Twitter
          </a>
        </div>
        <div className="links__link">
          <h2 className="subtitle">Legal</h2>
          <a href="/" className="text">
            Privacy policy
          </a>
          <a href="/" className="text">
            Términos de servicio
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.nav`
  padding: 3rem 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  .links {
    display: flex;
    justify-content: space-between;

    /* @media (max-width: 500px) {
      flex-wrap: wrap;
    } */

    &__link {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &:not(:last-child) {
        margin-right: 5rem;
      }

      .subtitle {
        text-transform: uppercase;
        color: #ffb300;
        font-weight: 400;
        margin-bottom: 1rem;
        font-size: 1.7rem;
      }

      .text {
        margin-bottom: 0.5rem;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  img {
    height: 6rem;

    @media (max-width: 700px) {
      margin-bottom: 4rem;
    }
  }
`;
