import React from "react";
import styled from "styled-components";

export default function WhatIsSection() {
  return (
    <WhatIsSectionWrapper>
      <div className="info">
        <h1 className="title">¿Qué es El Dorado?</h1>
        <h2 className="subtitle">
          Somos una alternativa de banca digital directamente en tu bolsillo.
        </h2>
        <div className="text">
          Crea una cuenta de ahorros en dólares de forma gratuita y realiza
          pagos a tus amigos, familiares y comercios favoritos. También puedes
          enviar remesas a Venezuela con las menores, o mas bajas comisiones del
          mercado, o comprar dólares estadounidenses y Bitcoins y almacénarlos
          de forma segura en nuestras cuentas.
        </div>
      </div>
      <img src={require("../../assets/images/home/map.png")} alt="map" />
    </WhatIsSectionWrapper>
  );
}

const WhatIsSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 8%;
  margin: 3rem 0;
  background: #f5f5f5;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    padding: 5rem 8%;
  }

  .info {
    .title {
      margin-bottom: 2rem;
    }

    .subtitle {
      margin-bottom: 1rem;
    }
  }

  img {
    width: 55rem;

    @media (max-width: 900px) {
      width: 100%;
      margin-top: 4rem;
    }
  }
`;
