import React from "react";
import styled from "styled-components";

export default function JoinUsSection() {
  return (
    <JoinUsSectionWrapper>
      <img
        src={require("../../assets/images/home/services-tree.png")}
        alt="join us"
      />
      <div className="info">
        <h1 className="title">Únete a nuesta comunidad</h1>
        <p className="text">
          Sé protagonista de la evolución del dinero en la emergente economía
          digital. Impulsamos tu calidad de vida ofreciéndote las mejores
          herramientas para resguardar el valor de tu trabajo y conectarte con
          una comunidad financieramente independiente.
        </p>
      </div>
    </JoinUsSectionWrapper>
  );
}

const JoinUsSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5rem 8%;
  margin: 3rem 0;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 42rem;

    @media (max-width: 700px) {
      width: 35rem;
    }
  }

  .info {
    margin-left: 10rem;
    margin-top: 3rem;

    @media (max-width: 700px) {
      margin-left: 0;
    }

    .title {
      margin-bottom: 2rem;
    }
  }
`;
